// import React, { useEffect, useRef } from 'react'
import React from 'react'

import "../css/normalize.css"
import "../css/reset.css"
import "../css/custom.css"
import "../css/fonts.css"
// import Typing from 'react-typing-animation'
import Application from "../components/Application"
import Header from "../components/header"
import AboutBody from "../components/aboutBody"
import Footer from "../components/footer"

export default function About() {

  // const tSpeed = 5;

  

  return (
    <div>
      <Header href="../" className="about"></Header>
      <div id="content" className="clearfix">
        <section className="sectionspace aboutParent">
          <div className="sectiongroup insidepage">
            <div className="aboutWrap in">

              <AboutBody></AboutBody>

              <h3 title="Exhibits &amp; Experience">
                {/* <span className="clickarrow"></span> */}
                <div className="clicknum">Exhibits &amp; Experience</div>
                <span className="clickline"></span>
              </h3>

              <p>
                <h4><a href="#">I AM/WERE HERE/THERE #1 &amp; 2</a></h4>
                <ul>
                  <li>Microwave International New Media Arts Festival 2018</li>
                  <li>International Symposium on Electronic Art ISEA2019 Gwangju 2019</li>
                  <li>COMO &amp; HAPPY SCREEN 2019 at Seoul</li>
                </ul></p>
              <p>
                <h4><a href="#">MOUNTAIN &amp; WATER</a></h4>
                <ul>
                  <li>Selected as The Finalist of The Hopper Prize 2019</li>
                  <li>DesignInspire 2019 at Hong Kong</li></ul>
              </p>


              {/* <h3 title="Experiences">
                <span className="clickarrow"></span>
                <div className="clicknum">Experience</div>
                <span className="clickline"></span>
              </h3>

              <p>
                <h4><a href="#">don't believe in style</a></h4>
              <ul>
                <li>Innovation Director (2017 – Present)</li>
                <li>Associate Creative Director (2014 – 2017)</li>
                <li>Online &amp; Interactive Designer (2012 – 2014)</li>
                </ul>
                </p>

              <p>
                <h4><a href="#">don't believe in style</a></h4>
              <ul>
                <li>Web Designer (2009 – 2012)</li>
              </ul></p> */}




            </div>
          </div>
        </section>
      </div>

      <Footer pageNow={"about"} ></Footer>


      <div id="aframe"></div>

      <Application pageNow=""></Application>
    </div >
  )
}